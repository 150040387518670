import { checkLocalhost } from "helpers/utils";
import { generateUrl, getPathFromSlug } from "../data/utils";
import { getCountryAndLanguage } from "../locale/utils";
import { isEmpty } from "lodash";
import { useRoutingContext } from "contexts/routing-context";
import localeData from "../../locale-data.json";

import {
  getLandingpageName,
  getLandingpageSubpath,
  isQueryOrHash,
} from "./utils";

type LandingpageConfig =
  (typeof localeData.landingpages)[keyof typeof localeData.landingpages];

const getSlugPath = (
  locale: string,
  slug: string | string[],
  currentLocale: string,
  previousPath?: string,
) => {
  const [, language] = getCountryAndLanguage(locale);
  const [, currentLanguage] = getCountryAndLanguage(currentLocale);

  const slugString = getPathFromSlug(slug);

  const pathFromSlug =
    previousPath && isQueryOrHash(slugString)
      ? `${previousPath}${slugString}`
      : slugString;

  // Make sure, no language is included in slug (because it would be duplicated)
  // and slug does not start with slash
  const slugPath = pathFromSlug
    .replace(/^\//, "")
    .replace(new RegExp(`^${locale}/`), "")
    .replace(new RegExp(`^${language}/`), "")
    .replace(new RegExp(`^${currentLanguage}/`), "")
    .replace(/^\//, "");

  return slugPath;
};

/**
 * Generate a href string for a given locale and slug.
 *
 * @param locale destination locale, in format `${country}_${language}` e.g. "de_de"
 * @param slug as string, e. g. "company/events", or string array, e. g. ["company", "events"]
 * @param currentLocale (source) locale of current page, in format `${country}_${language}` e.g. "k_en"
 * @param currentPath (source) path of current page, e.g. "/industries/oil-gas-industry"
 * @returns a string with either a absolute path if the destination country is the same as the current country (or local routing), or else a complete url with correct subdomain
 */
export const generateRoute = (
  locale: string,
  slug: string | string[],
  currentLocale?: string,
  currentPath?: string,
) => {
  let [country, language] = getCountryAndLanguage(locale);

  if (!country || !language) return;

  const [currentCountry] = getCountryAndLanguage(currentLocale);

  const currentSlugPath = getSlugPath(locale, currentPath, currentLocale);
  const slugPath = getSlugPath(locale, slug, currentLocale, currentPath);

  const currentLandingpageName = getLandingpageName(currentPath);
  const landingpageName = getLandingpageName(slugPath);
  const destinationIsCurrentLandingpage =
    landingpageName === currentLandingpageName && landingpageName;
  const isLandingpageSwitch = landingpageName !== currentLandingpageName;
  const landingpageConfig =
    landingpageName &&
    localeData.landingpages[
      landingpageName as keyof typeof localeData.landingpages
    ];

  const validatedLocale = validateLocaleWithLandingpage(
    locale,
    landingpageConfig,
  );
  if (validatedLocale !== locale) {
    [country, language] = getCountryAndLanguage(validatedLocale);
    locale = validatedLocale;
  }

  const isLocalhost = checkLocalhost();
  const destinationIsCurrentCountry =
    country === currentCountry || !currentLocale;

  // if not link to landingpage but using "lp" country,
  // change to normal country with corresponding language
  if (!isEmpty(slug) && !landingpageName && country === "lp") {
    locale = getLinkLocaleFromLandingpageLanguage(language);
    [country, language] = getCountryAndLanguage(locale);
  }

  if (isLocalhost) {
    return `/${locale}/${slugPath}`;
  }

  // If we switch landingpages (or to or from a landingpage)
  // or we are switching countries,
  // we need to generate a full url because the subdomain changes
  if (isLandingpageSwitch || !destinationIsCurrentCountry) {
    return generateUrl(
      locale,
      isLandingpageSwitch && destinationIsCurrentCountry && isEmpty(slug)
        ? currentSlugPath
        : slugPath,
    );
  }

  // If we stay on the same landingpage, we can use path routing
  if (destinationIsCurrentLandingpage) {
    const landingpagePath = getLandingpageSubpath(slugPath);

    return `/${language}/${landingpagePath}`;
  }

  return `/${language}/${slugPath}`;
};

/**
 * Validates a locale for a landingpage.
 *
 * @param locale Locale to validate e.g. "de_en"
 * @param landingpageConfig config of the landingpage from localeData
 * @returns the validated locale, e.g. "lp_en" if the locale is not valid for the landingpage
 */
export const validateLocaleWithLandingpage = (
  locale: string,
  landingpageConfig: LandingpageConfig,
) => {
  let [country, language] = getCountryAndLanguage(locale);

  if (!country || !language || !landingpageConfig) return locale;

  const {
    country: landingpageCountry,
    languages: landingpageLanguages,
    defaultLanguage: landingpageDefaultLanguage,
  } = landingpageConfig;

  const languageIsLandingpageLanguage = landingpageLanguages.includes(language);

  if (country !== landingpageCountry) {
    country = landingpageCountry;
  }

  if (!languageIsLandingpageLanguage) {
    language = landingpageDefaultLanguage;
  }

  return `${country}_${language}`;
};

/**
 * Because `useRouter().pathname` only returns the route (e. g. "/[locale]/[[...slug]]") and
 * `useRouter().query.slug` is wrong on landingpage subpages (e. g. "/applications/liquid" insead of "/landingpages/level-application-selector/applications/liquid")
 * you can get the current slug path with this hook. Only works when pageProps contains `slugPath` and only in subcomponents of `App`. Falls back to empty string.
 *
 * @returns current path as string, e. g. "/company/events" (without search params and locale)
 */
export const useSlugPath = () => {
  const { slugPath } = useRoutingContext();
  return slugPath ?? "";
};

/**
 * Because the country "lp" is a virtual one only for landingpages, the links on the landingpages to normal content should not link to the "lp" country, but to the locale generated by this function.
 *
 * @param language Language code of the desired link language
 * @returns the locale code for the given language, either from Group "k", from own country or first locale found with desired language
 */
export const getLinkLocaleFromLandingpageLanguage = (language: string) => {
  // try getting locale from Group "k"
  const kLocaleCandidate = localeData.locales.find((locale) => {
    const [country, lang] = getCountryAndLanguage(locale);
    return country === "k" && lang === language;
  });
  if (kLocaleCandidate) return kLocaleCandidate;

  // try getting locale from country which default language matches the desired language
  const ownCountryLocaleCandidate = Object.values(
    localeData.defaultLanguages,
  ).find((defaultLocale) => {
    const [, lang] = getCountryAndLanguage(defaultLocale);
    return lang === language;
  });
  if (ownCountryLocaleCandidate) return ownCountryLocaleCandidate;

  // fallback to first locale found with desired language
  return localeData.locales.find((locale) => {
    const [, lang] = getCountryAndLanguage(locale);
    return lang === language;
  });
};
