import { Shape } from "components/shape";
import {
  ContentsetContextProps,
  ProcessedFilter,
  SelectedOptions,
} from "constants/types";
import { useContentsetContext } from "contexts/contentset-context";
import { sanitize } from "helpers/text-processing";
import { FC } from "react";
import { colors } from "theme/theme";

interface FilterSelectedOptionTagProps {
  label: string;
  onClick?: () => void;
}

export const FilterSelectedOptionTag: FC<FilterSelectedOptionTagProps> = ({
  label,
  onClick,
  ...props
}) => {
  return (
    <button className="filter-selected-option-tag" onClick={onClick} {...props}>
      <Shape variant="close" fill={colors.blue} />
      <span
        className="label"
        dangerouslySetInnerHTML={{ __html: sanitize(label) }}
      ></span>
    </button>
  );
};

/**
 * Converts `selectedOptions` to an array of tags, so it gets flattened, and these tags additionally contain the `label` of the selected options extracted out of the `filters`.
 *
 * @param selectedOptions selected options which should be converted to tags
 * @param filters filters where `selectedOptions` should be included in their options
 * @returns an array of tags from `selectedOptions`, which each contain the `key` which corresponds with the `selectedOptions` key e.g. "nv_products§nv_flow_measurement§category§radio" and label which is the label of the selected option e.g. "Flow mesurement"
 */
const selectedOptionTags = (
  selectedOptions: SelectedOptions,
  filters: ProcessedFilter[],
): { key: string; label: string }[] => {
  return Object.keys(selectedOptions).reduce((tags, key) => {
    const [value, optionCode, type] = key.split("§");

    const filter = filters.find((filter) => filter[`filter_${type}`] === value);
    const option = filter?.options?.find(
      (option) => option.code === optionCode,
    );

    const tag = {
      key,
      label: option?.label,
    };

    if (typeof selectedOptions[key] === "object") {
      return [
        ...tags,
        tag,
        ...selectedOptionTags(selectedOptions[key], filter.child_filter),
      ];
    }

    return [...tags, tag];
  }, []);
};

/**
 * Removes the key `option` and its value (and therefore subobjects) from the object `selectedOptions`.
 *
 * @param option key which should be removed from `selectedOptions`
 * @param selectedOptions object to remove `option` from
 * @returns `selectedOptions` without `option`
 */
const removeOption = (
  option: string,
  selectedOptions: SelectedOptions,
): SelectedOptions => {
  return Object.keys(selectedOptions).reduce((newSelectedOptions, key) => {
    if (key === option) {
      return newSelectedOptions;
    }

    const value = selectedOptions[key];

    if (typeof value === "object" && Object.keys(value).length > 0) {
      return { ...newSelectedOptions, [key]: removeOption(option, value) };
    }

    return { ...newSelectedOptions, [key]: value };
  }, {});
};

/**
 * Converts `searchInput` and `seachValue` to an array of tags, so it gets flattened, and these tags additionally contain the `label` which should be the search phrase or value.
 *
 * @param searchInput search input which should be converted to tags
 * @param searchValue search value which should be converted to tags
 * @returns an array of tags from `searchInput` and `searchValue`, which each contain the `key` and a `label` which is the search phrase or value and
 * the type `input` if the tag comes from `searchInput` or `value` if the tag comes from `searchValue`
 */
const computeSearchTags = (
  searchInput: ContentsetContextProps["searchInput"],
  searchValue: ContentsetContextProps["searchValue"],
  filters: ProcessedFilter[],
): { key: string; label: string; type: "input" | "value" }[] => {
  return [
    ...Object.entries(searchInput)
      .map(([key, value]) => {
        if (!filters.some((filter) => filter.filter_attribute === key))
          return null;

        return {
          key,
          label: value,
          type: "input",
        } as const;
      })
      .filter(Boolean),
    ...Object.entries(searchValue)
      .map(([key, value]) => {
        if (!filters.some((filter) => filter.filter_attribute === key))
          return null;

        // Only happens if selected option was a krohne pick
        if (!value) return null;

        return {
          key,
          label: value.label,
          type: "value",
        } as const;
      })
      .filter(Boolean),
  ];
};

/**
 * Removes the `key` and its value from the object `selectedOptions`.
 *
 * @param key key which should be removed from `selectedOptions`
 * @param searchObject object to remove `key` from
 * @returns `searchObject` without `key`
 */
const removeSearch = (
  key: string,
  searchObject: { [key: string]: any },
): { [key: string]: any } => {
  return Object.keys(searchObject).reduce((newSearchObject, searchKey) => {
    if (searchKey === key) {
      return newSearchObject;
    }

    return { ...newSearchObject, [searchKey]: searchObject[searchKey] };
  }, {});
};

interface FilterSelectedOptionTagsProps {
  showSearchTags?: boolean;
}

export const FilterSelectedOptionTags: FC<FilterSelectedOptionTagsProps> = ({
  showSearchTags,
}) => {
  const {
    selectedOptions,
    setSelectedOptions,
    filters,
    searchInput,
    setSearchInput,
    searchValue,
    setSearchValue,
  } = useContentsetContext();

  const searchTags = computeSearchTags(searchInput, searchValue, filters);

  const tags = selectedOptionTags(selectedOptions, filters);

  return (
    <>
      {showSearchTags &&
        searchTags.map((tag) => (
          <FilterSelectedOptionTag
            label={tag.type === "input" ? `"${tag.label}"` : tag.label}
            onClick={() => {
              setSearchInput((searchInput) =>
                removeSearch(tag.key, searchInput),
              );
              setSearchValue((searchValue) =>
                removeSearch(tag.key, searchValue),
              );
            }}
            key={tag.key}
          />
        ))}
      {tags.map((tag) => (
        <FilterSelectedOptionTag
          label={tag.label}
          onClick={() => {
            setSelectedOptions((selectedOptions) =>
              removeOption(tag.key, selectedOptions),
            );
          }}
          key={tag.key}
        />
      ))}
    </>
  );
};
