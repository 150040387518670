import { Label, LabelContextProps } from "constants/types";
import { useLabelContext } from "contexts/label-context";
import { unescape } from "helpers/text-processing";
import { isEmpty } from "lodash/fp";
import { useRouter } from "next/router";
import { useCallback, useEffect, useRef, useState } from "react";

/**
 * Gets labels by ID for usage in components.
 *
 * @param uiIndentifier array with Ui IDs as string (`"ui-123"`) or number (`123`),
 * optionally with fallback value as second parameter in touple/array (`["ui-123", "fallback text"]`)
 * @returns array of Labels with corresponding IDs in same order as `labels` parameter, falls back to a Label with fallback value
 * for `title`, `short_title` and `label` and an empty string for `title_prefix` and `tooltip_text` or null, if no label with corresponding ID could be found
 * @example const [emailLabel, contactLabel] = useLabels(["ui-212", "Email"], [613, "Contact"]); // with fallback value
 * const [emailLabel, contactLabel] = useLabels("ui-212", 613); // without fallback value
 */
export const useLabels = (
  storeOrFirstIdentifier:
    | LabelContextProps
    | (string | number | [string | number, string]),
  ...uiIndentifier: (string | number | [string | number, string])[]
): (Label | null)[] => {
  const firstParameterIsStore =
    typeof storeOrFirstIdentifier === "object" &&
    !Array.isArray(storeOrFirstIdentifier);
  const store = firstParameterIsStore
    ? storeOrFirstIdentifier
    : // eslint-disable-next-line react-hooks/rules-of-hooks
      useLabelContext();

  if (!firstParameterIsStore) {
    uiIndentifier = [storeOrFirstIdentifier, ...uiIndentifier];
  }

  return uiIndentifier.map((query) => {
    const id = Array.isArray(query) ? query[0] : query;

    const fallback = Array.isArray(query) ? query[1] : "";
    const fallbackLabel: Label = {
      label: fallback,
      short_title: fallback,
      title: fallback,
      title_prefix: "",
      tooltip_text: "",
    };
    const requiredLabelFields: Label = {
      label: "",
      short_title: "",
      title: "",
      title_prefix: "",
      tooltip_text: "",
    };

    const accessor = typeof id === "number" ? `ui-${id}` : id;
    const match = store?.[accessor]
      ? { ...requiredLabelFields, ...store[accessor] }
      : null;

    return match ?? (Array.isArray(query) ? fallbackLabel : null);
  });
};

/**
 * Ensures that page scrolls instantly to top on route changes.
 */
export const useNormalScrollRoutes = () => {
  const router = useRouter();

  useEffect(() => {
    router.events.on("routeChangeStart", (url, options) => {
      if (options?.shallow || options?.scroll === false) return;
      document.documentElement.classList.add("normal-scroll");
    });
    router.events.on("routeChangeComplete", () => {
      document.documentElement.classList.remove("normal-scroll");
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

/**
 * Get the previous value of a variable.
 *
 * @param value to be saved
 * @returns previous value which was parsed to hook, falls back to undefined
 */
export const usePrevious = <T>(value: T): T | undefined => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

/**
 * Gets and sets a value to session storage.
 *
 * @param key key string for the session storage field which should be read and to which should be written
 * @returns a touple/array with a getter and setter function, similar to setState except first value is also a function
 * @example const [getValue, setValue] = useSessionStorage("key");
 */
export const useSessionStorage: (
  key: string,
) => [() => any, (value: any) => void] = (key) => {
  if (typeof window === "undefined" || !window || !("sessionStorage" in window))
    return [() => {}, (value) => {}];

  return [
    () => {
      const rawStore = window.sessionStorage.getItem(key);
      let store;
      try {
        store = JSON.parse(rawStore);
      } catch (e) {}
      return store;
    },
    (value) => {
      window.sessionStorage.setItem(key, JSON.stringify(value));
    },
  ];
};

/**
 * Gets and sets a value to local storage.
 *
 * @param key key string for the local storage field which should be read and to which should be written
 * @returns a touple/array with a getter and setter function, similar to setState except first value is also a function
 * @example const [getValue, setValue] = useLocalStorage("key");
 */
export const useLocalStorage: (
  key: string,
) => [() => any, (value: any) => void] = (key) => {
  if (typeof window === "undefined" || !window || !("localStorage" in window)) {
    return [() => null, () => null];
  }

  return [
    () => {
      const rawStore = window.localStorage?.getItem(key);
      let store;
      try {
        store = JSON.parse(rawStore);
      } catch (e) {}
      return store;
    },
    (value) => {
      window.localStorage?.setItem(key, JSON.stringify(value));
    },
  ];
};

/**
 * @param key local storage key where result is cached, could be a route or url of api endpoint
 * @param fetcher fetcher function which resolves result, gets key parameter
 * @param maxCacheTimeInSeconds optional parameter to set a maximum cache time, default is 1 day
 * @returns a touple/array with cached and later fetched result, and setter, similar to useState
 * @example const [countries, setCountries] = useCachedFetch(`/api/countries`, (apiURL: string) => fetch(apiURL).then((res) => res.json()))
 */
export const useCachedFetch: (
  key: string,
  fetcher: (key: string) => Promise<any>,
  maxCacheTimeInSeconds?: number,
) => [any, (value: any) => void] = (
  key,
  fetcher,
  maxCacheTimeInSeconds = 60 * 60 * 24,
) => {
  const isBrowser = typeof window !== "undefined";

  const [getLocalStorage, setLocaleStorage] =
    key && typeof key === "string"
      ? // eslint-disable-next-line react-hooks/rules-of-hooks
        useLocalStorage(key)
      : [() => null, () => null];

  const [data, setData] = useState(getLocalStorage()?.data);

  const setStore = (value: any) => {
    setLocaleStorage({ data: value, date: Date.now() });
    setData(value);
  };

  useEffect(() => {
    if (!key || typeof key !== "string") return;
    const cached = getLocalStorage();
    setData(cached?.data);

    // Only fetch if there is no data or there is an error or max cache time is exceeded
    // and we are in the browser
    if (
      (!cached?.data ||
        cached?.data?.error ||
        cached?.date > Date.now() + maxCacheTimeInSeconds * 1000) &&
      isBrowser
    ) {
      fetcher(key).then(setStore).catch(console.error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key]);

  return [data, setStore];
};

/**
 * UseEffect that does not run on first render.
 * @param func Function to run in useEffect
 * @param deps UseEffect Dependencies
 */
export const useDidMountEffect = (func, deps) => {
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) func();
    else didMount.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};

const parseQuery = <T>(key: string, defaultValue: T): T => {
  if (typeof window === "undefined") {
    return defaultValue;
  }

  const urlSearch = new URLSearchParams(window.location.search);
  const preSelection = urlSearch.get(key);
  try {
    if (preSelection) {
      return JSON.parse(unescape(preSelection));
    }
  } catch (err) {
    return (unescape(preSelection) || defaultValue) as T;
  }
  return defaultValue;
};

export const buildQueryUrl = <T>(
  key: string,
  value: T,
  initialValue: T,
  path: string,
): [string, boolean] => {
  const [pathname, searchAndHash] = path?.split("?");
  const [fallbackSearch, hash] = searchAndHash?.split("#") ?? ["", ""];
  let search = fallbackSearch;
  if (typeof window !== "undefined") {
    search = window.location.search;
  }
  const oldUrlSearch = new URLSearchParams(search);
  const urlSearch = new URLSearchParams(search);

  urlSearch.delete(key);

  for (const key in value) {
    if (isEmpty(value[key])) {
      delete value[key];
    }
  }

  if (
    (typeof value === "object" ? Object.values(value).length : value) &&
    value !== initialValue
  ) {
    urlSearch.append(
      key,
      typeof value === "object" ? JSON.stringify(value) : String(value),
    );
  }

  const urlSearchString = urlSearch.toString();
  let newUrl = `${
    typeof window === "undefined" ? pathname : window.location.pathname
  }`;
  if (urlSearchString) newUrl += `?${urlSearchString}`;
  if (typeof window === "undefined") {
    if (hash) {
      newUrl += hash;
    }
  } else if (window.location.hash) {
    newUrl += window.location.hash;
  }

  return [newUrl, urlSearchString !== oldUrlSearch.toString()];
};

const setQuery = <T>(key: string, value: T, initialValue: T, path: string) => {
  const [newUrl, changed] = buildQueryUrl(key, value, initialValue, path);
  if (changed) {
    window.history.replaceState(
      { ...window.history.state, as: newUrl, url: newUrl },
      "",
      newUrl,
    );
  }
};

export const useQueryState: <T>(
  keys: string | string[],
  initialValue: T,
) => [T, (newValue: T) => void] = (keys, initialValue) => {
  const { asPath, query } = useRouter();

  const key =
    typeof keys === "string"
      ? keys
      : keys.find((k) => typeof query[k] !== "undefined") ?? keys[0];

  const [value, setValue] = useState(parseQuery(key, initialValue));
  const onSetValue = useCallback(
    (newValue) => {
      if (typeof newValue === "function") {
        newValue = newValue(value);
      }
      setValue(newValue);
      setQuery(key, newValue, initialValue, asPath);
    },
    [asPath, initialValue, key, value],
  );

  useEffect(() => {
    setValue(parseQuery(key, initialValue));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  return [value, onSetValue];
};

/**
 * Hook to get the current fullscreen state and toggle it.
 * @example
 * const [isFullscreen, toggleFullscreen] = useFullscreen();
 */
export const useFullscreen = () => {
  const safeDocument = typeof document !== "undefined" ? document : null;

  const [isFullscreen, setIsFullscreen] = useState(
    !!safeDocument?.fullscreenElement,
  );

  const toggleFullscreen = useCallback(() => {
    if (safeDocument.fullscreenElement) {
      void safeDocument?.exitFullscreen();
    } else {
      void safeDocument?.documentElement.requestFullscreen();
    }
  }, [safeDocument]);

  useEffect(() => {
    const fullscreenChangeHandler = () => {
      setIsFullscreen(!!safeDocument.fullscreenElement);
    };
    safeDocument?.addEventListener("fullscreenchange", fullscreenChangeHandler);
    const exitFullscreenHandler = (event: KeyboardEvent) => {
      if (event.key !== "Escape") return;
      if (!safeDocument?.fullscreenElement) return;
      toggleFullscreen();
    };
    safeDocument?.addEventListener("keydown", exitFullscreenHandler);
    return () => {
      safeDocument?.removeEventListener(
        "fullscreenchange",
        fullscreenChangeHandler,
      );
      safeDocument?.removeEventListener("keydown", exitFullscreenHandler);
    };
  }, [safeDocument, toggleFullscreen]);

  if (typeof document === "undefined") {
    return [false, () => {}] as const;
  }

  return [isFullscreen, toggleFullscreen] as const;
};
