import { AdvancedVideo } from "@cloudinary/react";
import { Cloudinary as CloudinaryUrlGen } from "@cloudinary/url-gen";
import {
  dpr as cldDpr,
  defaultImage,
  format,
  quality,
} from "@cloudinary/url-gen/actions/delivery";
import { trim } from "@cloudinary/url-gen/actions/reshape";
import { scale } from "@cloudinary/url-gen/actions/resize";
import { auto as autoFormat } from "@cloudinary/url-gen/qualifiers/format";
import { auto as autoQuality } from "@cloudinary/url-gen/qualifiers/quality";
import { ResizeObserver as Polyfill } from "@juggle/resize-observer";
import classNames from "classnames";
import { usePreload } from "components/preload";
import {
  CloudinaryItemProps,
  CloudinaryUrlProps,
  CloudinaryVideoProps,
} from "constants/types";
import { useLocale } from "helpers/locale";
import { getSpeakingId, sanitize } from "helpers/text-processing";
import { equals } from "lodash/fp";
import getConfig from "next/config";
import Image from "next/image";
import {
  forwardRef,
  memo,
  Ref,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

/*

  ###         Redo this component using cloudinary react library        ###
  ### https://cloudinary.com/documentation/react2_image_transformations ###

*/

const { publicRuntimeConfig } = getConfig();

const CLOUDINARY_CLOUD_NAME = "krohne";
const CLOUDINARY_OPTIONS = {
  cloud_name: CLOUDINARY_CLOUD_NAME,
  secure: true,
  private_cdn: true,
  cname: publicRuntimeConfig.CLOUDINARY_BASE_URL,
  secure_distribution: publicRuntimeConfig.CLOUDINARY_BASE_URL,
};

const aspectRatioMap = {
  ar43: {
    slug: "-4-3.jpg,ar_1.33",
    img: "-4-3",
    css: "4/3",
    size: { width: 400, height: 300, multiplier: 1.33 },
  },
  ar2410: {
    slug: "-24-10.jpg,ar_2.4",
    img: "-24-10",
    css: "24/10",
    size: { width: 240, height: 100, multiplier: 2.4 },
  },
  ar34: {
    slug: "-3-4.jpg,ar_0.75",
    img: "-3-4",
    css: "3/4",
    size: { width: 300, height: 400, multiplier: 0.75 },
  },
  ar169: {
    slug: "-16-9.jpg,ar_1.78",
    img: "-16-9",
    css: "16/9",
    size: { width: 160, height: 90, multiplier: 1.78 },
  },
  ar165: {
    slug: "-16-5.jpg,ar_3.2",
    img: "-16-5",
    css: "16/5",
    size: { width: 160, height: 50, multiplier: 3.2 },
  },
  ar11: {
    slug: "-1-1.jpg,ar_1",
    img: "-1-1",
    css: "1/1",
    size: { width: 100, height: 100, multiplier: 1 },
  },
  ar32: {
    slug: "-3-2.jpg,ar_0.66",
    img: "-3-2",
    css: "3/2",
    size: { width: 300, height: 200, multiplier: 0.66 },
  },
};

// jump to next image width, if for example an image has a width of 315px it will jump to 540px.
// this is to prevent to cache thousand of different image sizes on cloudinary size.
// but to maybe increase the steps, we could add more "jump" values inhere.
// for example, take the container width and all possible widths (1380px) but bear in mind
// that the container width changes for diffenrent breakpoints
// col-2 on 1380 would be 230 pixel, col-3 => 345, col-4 => 460, col-6 => 690 etc.
// also if the pixel-ratio is set, width gets doubled on cloudinary. so a 1920 width image,
// is 3840 images in width, and would be pretty huge
const closest = (size) =>
  [270, 540, 720, 960, 1140, 1920, 2048, 3840].reduce((a, b) =>
    size <= a ? a : b,
  );

// TODO: if height is set, no w_auto is set, so it wont be replaced and then the cropping is set and wont match the aspect ratio
// so this means, if only height is set, get aspect ratio and multiply the height by it
/*
https://dam.krohne.com/t_ar169_cr_c/h_675/u_im-background:grey-triangle-16-9.jpg,ar_1.78,w_1140/c_scale/q_auto/dpr_2/f_auto/d_im-other:image-not-available.png/im-photo-composition/krohne-engineered-solutions-process-control-automation
after resize:
https://dam.krohne.com/t_ar169_cr_c/h_675/u_im-background:grey-triangle-16-9.jpg,ar_1.78,w_1920/c_scale/q_auto/dpr_2/f_auto/d_im-other:image-not-available.png/im-photo-composition/krohne-engineered-solutions-process-control-automation
*/
const reponsiveUrl = (
  url: string,
  size: number,
  dimension: "height" | "width" = "width",
) => {
  if (dimension === "height") {
    url = url.replace(/w_auto/g, `h_${size || "auto"}`);
  } else if (dimension === "width") {
    url = url.replace(/w_auto/g, `w_${size || "auto"}`);
  }
  return url;
};

function digitalAssetTransformation(media, ar, overwriteNamedTransformation) {
  let namedTransformation = "";
  if (typeof media === "undefined") return;
  if (
    media[`digital_asset_named_transformation_${ar}`] ||
    media[`digital_asset_custom_transformation_${ar}`]
  ) {
    const arElement = media[`digital_asset_named_transformation_${ar}`]?.[0];
    if (arElement?.code) {
      namedTransformation = `/${arElement.code}`;
    } else if (overwriteNamedTransformation) {
      namedTransformation = "/" + overwriteNamedTransformation;
    }

    if (media[`digital_asset_custom_transformation_${ar}`]) {
      const arElement = media[`digital_asset_custom_transformation_${ar}`];
      if (arElement) {
        namedTransformation = `/${arElement}`;
      }
    }
  }

  return namedTransformation;
}

const cleanUpSlashes = (transformationStr: string) => {
  let transformedStr = transformationStr;
  if (transformedStr.endsWith("/")) {
    transformedStr = transformedStr.slice(0, -1);
  }

  if (transformedStr.startsWith("/")) {
    transformedStr = transformedStr.slice(1);
  }

  return transformedStr;
};

export const CloudinaryUrlHandler = ({
  media, // The media asset
  auto = true, // Boolean value to automatically adjust image size
  width = null, // Width of the media asset
  height = null, // Height of the media asset
  crop = null, // Crop option for the media asset
  ar = null, // Aspect ratio for the media asset
  fileExtension = null, // File extension for the media asset
  download = false, // Download option for the media asset
  background = null, // Background option for the media asset
  arBackground = null, // Aspect ratio for the background
  widthBackground = null, // Width for the background
  overwriteNamedTransformation = null, // Option to overwrite named transformation
  dpr = "auto", // Device Pixel Ratio option for the media asset
  isChina = false, // Boolean value to use China CDN
}: CloudinaryUrlProps): string => {
  if (!media) return "";

  // Initialize the Cloudinary URL Generator with the given options
  /**
   * @returns instance of cloudinary
   *
   * @param {string} cloudName the cloudinary repo name
   * @param {boolean} privateCdn Set privateCdn to true to use a custom base URL
   * @param {string} secureDistribution Set the custom base URL
   * @param {secure} secure Set secure to true to use HTTPS
   */
  const cld = new CloudinaryUrlGen({
    cloud: {
      cloudName: CLOUDINARY_OPTIONS.cloud_name,
    },
    url: {
      privateCdn: true,
      secureDistribution: isChina
        ? publicRuntimeConfig.CLOUDINARY_CHINA_BASE_URL
        : publicRuntimeConfig.CLOUDINARY_BASE_URL,
      secure: true, // Set secure to true to use HTTPS
    },
  });

  // Initialize the image with the provided digital asset ID

  /**
   * @returns image object which can be generated as URL
   *
   * @param {string} media.digital.asset_id is the given cloudinary image public ID
   */
  let cldImg = cld.image(media.digital_asset_id);

  // If the media type is a video, use the video method instead
  if (media?.__typename === "Vi") {
    /**
     * @returns video object which can be generated as URL
     *
     * @param {string} media.digital.asset_id is the given cloudinary video public ID
     */
    cldImg = cld.video(media.digital_asset_id);
  }

  // If the download option is true, add an attachment flag to the image
  if (download) {
    cldImg = cld.image(media.digital_asset_id);
    /**
     * addFlag() Alters the regular behavior of another transformation or the overall delivery behavior.
     *
     * @param {string} flag The flag to add to the transformation.
     * or we can modify the downloaded filename by using attachment(filename) with
     * @param {string} filename
     *
     */
    cldImg.addFlag("attachment");
  } else {
    // If a digital asset transformation is provided in the media object, add it to the image as a transformation string
    if (
      typeof media === "object" &&
      "digital_asset_transformation" in media &&
      media.digital_asset_transformation
    ) {
      if (media.digital_asset_transformation.includes("video/upload")) {
        cldImg = cld.video(media.digital_asset_id);

        /**
         * addTransformation(transformation)
         * @param {string} transformation adds the provided transformation string directly into the image object e.g. w_100
         *
         */
        cldImg.addTransformation(
          media.digital_asset_transformation.replace("video/upload", ""),
        );
      } else {
        // digital_asset_transformation will be added as a transformation string in addTransformation()

        //the string from media.digital_asset_transformation sometimes starts or ends with '/', then we clean it up
        let transformationStr = media.digital_asset_transformation;

        transformationStr = cleanUpSlashes(transformationStr);

        cldImg.addTransformation(transformationStr);
      }
    }

    // If a crop option is provided, reshape the image with the color similarity method
    // i think it's mistaken with trim, therefore by looking at the given value, it's intended as trim instead of crop
    if (crop) {
      /**
       * Detects and removes image edges whose color is similar to the corner pixels.
       * @param {number} crop The tolerance level for the color similarity detection. The higher the tolerance, the more aggressive the trim.
       *
       * */
      cldImg.reshape(trim().colorSimilarity(crop));
    }

    // If an aspect ratio is provided, add it to the image transformation
    if (ar !== null) {
      //the string from digitalAssetTransformation() sometimes starts or ends with '/', then we clean it up
      let transformationStr = digitalAssetTransformation(
        media,
        ar,
        overwriteNamedTransformation,
      );

      transformationStr = cleanUpSlashes(transformationStr);

      cldImg.addTransformation(transformationStr);
    }

    // If an aspect ratio for the background is provided, add it to the image transformation
    if (arBackground !== null) {
      //the string from digitalAssetTransformation() sometimes starts or ends with '/', then we clean it up
      let transformationStr = digitalAssetTransformation(
        media,
        arBackground,
        overwriteNamedTransformation,
      );

      transformationStr = cleanUpSlashes(transformationStr);

      cldImg.addTransformation(transformationStr);
    }

    // If the auto option is true, resize the image width automatically, otherwise use the provided width and height
    /**
     * A qualifier that determines the width and/or the height of a transformed asset.
     * width/height can be used with either scale or crop, in this case we use scale
     * @param {integer | float | constant} width
     * @param {integer | float | constant} height
     * or
     * @param {string} auto A qualifier that determines how to automatically resize an image to match the width available for the image in a responsive layout
     *
     */
    if (auto && !width && !height) {
      cldImg.resize(scale().width("auto"));
    } else {
      if (width && height) {
        cldImg.resize(scale().width(width));
        cldImg.resize(scale().height(height));
      } else {
        if (height) {
          cldImg.resize(scale().height(height));
        }
        if (width) {
          cldImg.resize(scale().width(width));
        }
      }
    }

    // Add background transformation if the background property is provided
    // unlike the official SDK transformation overlay/underlay, this will be provided as string transformation, due to limitation
    if (background) {
      let imageBackground = "";

      if (background === "white") {
        imageBackground = `b_${background}`;
      } else if (background === "grey-triangle") {
        const bgRatio = arBackground || ar || "ar169";
        imageBackground = `u_im-background:${background}${aspectRatioMap[bgRatio].slug}`;
      }

      if (imageBackground !== "") {
        if (widthBackground) {
          imageBackground += `,w_${widthBackground}`;
        } else if (width) {
          imageBackground += `,w_${width}`;
        } else if (auto) {
          imageBackground += `,w_auto/c_scale`;
        }
      }
      cldImg.addTransformation(imageBackground);
    }

    /**
     * Alters the asset's delivery, e.g. quality, device-pixel-ratio, default image, and format.
     *
     * quality(auto()) Delivers an asset with an automatically determined level of quality.
     * dpr(dpr) aliased as cldDpr(dpr) Delivers the image or video in the specified device pixel ratio.
     * @param {float} dpr
     *
     * defaultImage(img) Specifies a backup placeholder image to be delivered in the case that the actual requested delivery image or social media picture does not exist.
     * Any requested transformations are applied on the placeholder image as well.
     * @param {string} img asset
     *
     */
    cldImg.delivery(quality(autoQuality()));
    cldImg.delivery(cldDpr(dpr));
    cldImg.delivery(defaultImage("im-other:image-not-available.png"));
  }

  // Add file extension (format) transformation if the fileExtension property is provided, or use pdf if the media is an attachment, otherwise auto format for the best result (usually webp).
  /**
   * format(arg) Converts (if necessary) and delivers an asset in the specified format regardless of the file extension used in the delivery URL.
   * @param {string} arg The format to use when delivering the asset.
   * or
   * @param {function} auto Automatically generates (if needed) and delivers an asset in the most optimal format for the requesting browser in order to minimize the file size.
   */
  if (fileExtension) {
    cldImg.delivery(format(fileExtension));
  } else if (download) {
    cldImg.delivery(format("pdf"));
  } else {
    cldImg.delivery(format(autoFormat()));
  }

  /**
   * @returns URL string from the transformed asset
   *
   * */
  return cldImg.toURL();
};

const shimmer = (w: number, h: number): string => `
<svg width="${w}" height="${h}" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <defs>
    <linearGradient id="g">
      <stop stop-color="#f2f4f5" offset="20%" />
      <stop stop-color="#f5f7f9" offset="50%" />
      <stop stop-color="#f2f4f5" offset="70%" />
    </linearGradient>
  </defs>
  <rect width="${w}" height="${h}" fill="#f2f4f5" />
  <rect id="r" width="${w}" height="${h}" fill="url(#g)" />
  <animate xlink:href="#r" attributeName="x" from="-${w}" to="${w}" dur="1s" repeatCount="indefinite"  />
</svg>`;

const animations = { shimmer };

const toBase64 = (str: string): string => Buffer.from(str).toString("base64");

const CloudinaryItem = (props: CloudinaryItemProps) => {
  const [dpr, setDpr] = useState("auto" as string);
  useEffect(() => setDpr(window.devicePixelRatio.toString()), []);

  // this will set height / width values, to render aspect ratio in nextjs wrapper correctly
  const size = useMemo<{
    height: number;
    multiplier: number;
    width: number;
  }>(() => {
    return aspectRatioMap[props.ar]?.size ?? aspectRatioMap.ar11?.size;
  }, [props.ar]);

  const { country } = useLocale();

  const getCldVariant = () => {
    switch (props?.fitStrategy) {
      case "cover":
        return "cld-responsive--cover";
      default:
        return "cld-responsive--contain";
    }
  };
  const { addPreload } = usePreload();

  useEffect(() => {
    if (props.priority) {
      const href_dpr_1 = reponsiveUrl(
        CloudinaryUrlHandler({
          ...props,
          dpr: "1.0",
          isChina: country === "cn",
        } as CloudinaryUrlProps),
        Math.max(props.containerWidth, props.containerHeight),
        props.containerWidth > props.containerHeight ? "width" : "height",
      );
      const href_dpr_2 = reponsiveUrl(
        CloudinaryUrlHandler({
          ...props,
          dpr: "2.0",
          isChina: country === "cn",
        } as CloudinaryUrlProps),
        Math.max(props.containerWidth, props.containerHeight),
        props.containerWidth > props.containerHeight ? "width" : "height",
      );

      addPreload([
        {
          href: href_dpr_1,
          media: "(-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi)",
        },
        {
          href: href_dpr_2,
          media:
            "(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)",
        },
      ]);
    }
  }, [props, addPreload, country]);

  return (
    <>
      <Image
        className={classNames(
          "cld-responsive",
          getCldVariant(),
          props?.border && "border",
        )}
        title={props.media?.title}
        priority={
          props.priority != null
            ? props.priority
            : props.media?.digital_asset_id?.includes("single-product")
            ? true
            : false
        }
        loading={
          props.priority ||
          props.media?.digital_asset_id?.includes("single-product")
            ? "eager"
            : props?.loading || "lazy"
        }
        src={reponsiveUrl(
          CloudinaryUrlHandler({
            ...props,
            dpr,
            isChina: country === "cn",
          } as CloudinaryUrlProps),
          Math.max(props.containerWidth, props.containerHeight),
          props.containerWidth > props.containerHeight ? "width" : "height",
        )}
        alt={props?.media?.alternative_text || ""}
        width={props?.width || size.width}
        height={props?.height || size.height}
        onLoad={props?.onLoad || null}
        itemProp="contentUrl"
        {...(props.animation !== "none" &&
          props.containerWidth > 40 &&
          (props?.width || size.width) > 40 && {
            placeholder: "blur",
            blurDataURL: `data:image/svg+xml;base64,${toBase64(
              animations.shimmer(
                props.containerWidth,
                props.containerWidth *
                  (aspectRatioMap[props.ar]?.size?.multiplier ??
                    aspectRatioMap.ar11?.size?.multiplier),
              ),
            )}`,
          })}
      />
      <meta itemProp="width" content={`${props?.width || size.width}`} />
      <meta itemProp="height" content={`${props?.height || size.height}`} />
    </>
  );
};

export const CloudinaryVideo = memo(
  forwardRef(function CloudinaryVideo(
    props: CloudinaryVideoProps,
    ref: Ref<AdvancedVideo>,
  ) {
    const { country } = useLocale();
    const fallbackRef = useRef<AdvancedVideo>();
    const cld = useMemo(
      () =>
        new CloudinaryUrlGen({
          cloud: {
            cloudName: CLOUDINARY_OPTIONS.cloud_name,
          },
          url: {
            privateCdn: true, // Set privateCdn to true to use a custom base URL
            secureDistribution:
              country === "cn"
                ? publicRuntimeConfig.CLOUDINARY_CHINA_BASE_URL
                : publicRuntimeConfig.CLOUDINARY_BASE_URL, // Set the custom base URL
            secure: true, // Set secure to true to use HTTPS
          },
        }),
      [country],
    );

    const [posterProp, setPosterProp] = useState(props?.poster);
    const video = useMemo(() => {
      const video = cld.video(props.media.digital_asset_id);
      if ("digital_asset_transformation" in props.media) {
        video.addTransformation(props.media.digital_asset_transformation);
      }
      return video;
    }, [cld, props.media]);

    useEffect(() => {
      if (
        !posterProp &&
        props.media?.key_visual?.[0] &&
        "digital_asset_id" in props.media.key_visual?.[0] &&
        props.media.key_visual?.[0].digital_asset_id
      ) {
        setPosterProp(props.media.key_visual?.[0]);
      }
    }, [posterProp, props.media.key_visual]);
    const poster = useRef(cld.image(posterProp?.digital_asset_id));

    /* docs: https://cloudinary.com/documentation/video_player_api_reference */
    return (
      <AdvancedVideo
        // className="cloudinary-context-video"
        cldVid={video}
        controls={!props?.hideControls}
        loop={props?.loop}
        autoPlay={!props?.noAutoPlay}
        muted={props?.muted}
        preload="auto"
        poster={posterProp ? poster.current.toURL() : null}
        ref={ref || fallbackRef}
        // sourceTypes={["hls"]}
        // streamingProfile="full_hd"
      />
    );
  }),
  (prevProps, nextProps) => {
    return equals(prevProps, nextProps);
  },
);

const ImageWrapper = (props) => {
  return (
    <>
      <CloudinaryItem {...props} />
      {props.caption && props.caption !== "none" && props.media?.caption && (
        <figcaption
          className="image-caption"
          id={getSpeakingId({ title: props.caption as string })}
          dangerouslySetInnerHTML={{
            __html: sanitize(props.caption ?? props.media?.caption),
          }}
        ></figcaption>
      )}
    </>
  );
};

export const Cloudinary = ({
  lazyload = true,
  animation = "shimmer",
  ...props
}: CloudinaryItemProps) => {
  const imageWrapper = useRef(null);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const observer = useRef(null);

  if (
    props.media?.key_visual?.[0] &&
    "digital_asset_id" in props.media.key_visual?.[0] &&
    props.media?.key_visual?.[0].digital_asset_id
  ) {
    props.media = props.media.key_visual?.[0];
  }

  if (
    !props.ar &&
    props?.media &&
    typeof props.media === "object" &&
    "aspect_ratio" in props.media &&
    props.media.aspect_ratio?.[0]?.code
  ) {
    props.ar = `ar${props.media.aspect_ratio[0].code.replace("_", "")}`;
  }

  useEffect(() => {
    const parentRect =
      imageWrapper?.current?.parentNode?.getBoundingClientRect?.();

    if (parentRect) {
      const { width, height } = parentRect;

      setHeight(closest(height));
      setWidth(closest(width));
    } else {
      setHeight(closest(0));
      setWidth(closest(0));
    }
  }, []);

  useEffect(() => {
    // setup resize observer
    const ResizeObserver = window.ResizeObserver || Polyfill;
    observer.current = new ResizeObserver((entries) => {
      const newHeight = closest(entries[0]?.contentRect?.height ?? 0);
      const newWidth = closest(entries[0]?.contentRect?.width ?? 0);

      // only set new width, on scale upwards
      if (newHeight > height) {
        setHeight(newHeight);
      }
      if (newWidth > width) {
        setWidth(newWidth);
      }
    });
    observer.current.observe(imageWrapper.current);

    return () => {
      observer.current.disconnect();
    };
  }, [height, width]);

  return (
    <figure
      className={classNames(
        "image-wrapper",
        !props?.width &&
          (props?.responsiveOrientation === "height" ? "h-100" : "w-100"),
        props?.className,
      )}
      style={{
        aspectRatio: aspectRatioMap[props.ar]?.css,
        flexShrink: 0,
      }}
      ref={imageWrapper}
      itemProp="image"
      itemScope
      itemType="https://schema.org/ImageObject"
    >
      {props.media && imageWrapper?.current !== null ? (
        <ImageWrapper
          alt={props.media.alternative_text || ""}
          animation={animation}
          containerHeight={height}
          containerWidth={width}
          lazyload={lazyload}
          {...props}
        />
      ) : null}
    </figure>
  );
};
