import { decodeAndRemoveTags } from "helpers/contentset/functions";
import { forwardRef, useEffect, useMemo, useState } from "react";
import { getSearchOptions } from "components/contentset";
import { SearchFilterProps, SelectOption } from "constants/types";
import { Select } from "components/forms/select";
import { useContentsetContext } from "contexts/contentset-context";
import { useLabels } from "helpers/hooks";
import { useLocale } from "helpers/locale";
import cloneDeep from "lodash/cloneDeep";
import omit from "lodash/omit";

export const checkForKrohnePick = (value: string) => {
  const serial = /(A|S|U|E|J|G|D|H|F|R|I|C|T|P|L|K)[0-9]{5,15}/g;
  const vnumber = /(V|S)[A-Za-z0-9]{4,30}/g;

  return serial.test(value) || vnumber.test(value);
};

export const filterOption = (
  { data: option }: SelectOption & { data: SelectOption },
  input: string,
) => {
  const inputWithoutTags = decodeAndRemoveTags(input);
  const labelWithoutTags = decodeAndRemoveTags(option.label);

  const searchAliasValue = option.search_alias
    ? decodeAndRemoveTags(option.search_alias)
    : null;

  // If search alias exists on the option we filter for that, otherwise we filter for the label
  return (
    searchAliasValue?.includes(inputWithoutTags) ||
    labelWithoutTags.includes(inputWithoutTags)
  );
};

export type SelectHandle = {
  handleExternalClick: (event) => void;
};

export const SearchFilter = forwardRef<SelectHandle, SearchFilterProps>(
  function SearchFilter(
    {
      externalState,
      externalInput,
      filter,
      inModal,
      searchFunction: externalSearchFunction,
    },
    ref,
  ) {
    const {
      searchValue,
      setSearchValue,
      searchInput,
      setSearchInput,
      selectedOptions,
      contentset,
      filterAndLoadMore,
      isLoadingContentsetPage,
    } = useContentsetContext();

    // Store krohnePick value if valid
    const [krohnePick, setKrohnePick] = useState<string>(null);
    const searchKey = useMemo(() => filter.filter_attribute, [filter]);
    const [noOptionsMessage] = useLabels(["ui-585", "No options"]);
    const [options, setOptions] = useState<SelectOption[]>([]);
    const [isLoadingOptions, setIsLoadingOptions] = useState<boolean>(false);

    const searchFunction = () => {
      externalSearchFunction?.();
      void filterAndLoadMore(0);
    };

    const handleInputChange = (str: string) => {
      if (externalInput?.handleChange) {
        externalInput?.handleChange(str || "");
      } else {
        if (str) {
          setSearchInput({ ...cloneDeep(searchInput), [searchKey]: str });
        } else {
          setSearchInput(omit(searchInput, [searchKey]));
        }
      }
      setKrohnePick(checkForKrohnePick(str) ? str : null);
    };

    const handleChange = (newValue) => {
      if (externalState?.handleChange) externalState?.handleChange(newValue);

      // Set search value
      if (newValue) {
        // Remove this field from input object if selecting an item
        if (externalInput?.handleChange) externalInput?.handleChange("");
        else setSearchInput(omit(searchInput, [searchKey]));
        // Set search value
        setSearchValue({ ...cloneDeep(searchValue), [searchKey]: newValue });
      } else {
        // Remove search value
        setSearchValue(omit(searchValue, [searchKey]));
      }
    };

    const { locale } = useLocale();

    const setOptionsAsync = async () => {
      setIsLoadingOptions(true);

      const filterValues = {
        selectedOptions,
        searchValue,
        searchInput: externalInput?.value
          ? {
              ...omit(searchInput, [searchKey]),
              [searchKey]: externalInput.value,
            }
          : searchInput,
      };

      const options = await getSearchOptions(
        contentset,
        locale,
        searchKey,
        filterValues,
      );

      if (krohnePick) {
        options.push({
          image: {
            media: {
              digital_asset_id: "im-background/grey-triangle-1-1",
            },
          },
          value: krohnePick,
          label: krohnePick,
          isKrohnePick: true,
        });
      }
      setOptions(options);
      setIsLoadingOptions(false);
    };

    useEffect(() => {
      void setOptionsAsync();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedOptions, searchValue, locale]);

    const entitesAreFiltered = () =>
      Object.keys(searchValue).length ||
      Boolean(Object.values(searchInput).find((i) => i.length >= 3)) ||
      Object.keys(selectedOptions).length;

    const shouldShowEntities = () =>
      !krohnePick && (!contentset.show_preview_filter || entitesAreFiltered());
    const [showEntities, setShowEntities] = useState(shouldShowEntities());

    useEffect(() => {
      setShowEntities(shouldShowEntities());
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [krohnePick, searchValue, searchInput, selectedOptions]);

    return (
      <Select
        options={options}
        value={(externalState?.value || searchValue[searchKey]) ?? null}
        handleChange={handleChange}
        externalInput={externalInput?.value ?? searchInput?.[searchKey] ?? ""}
        handleInputChange={handleInputChange}
        searchFunction={searchFunction}
        width={250}
        isSearch
        isDropdownSearch
        isLoading={showEntities && isLoadingContentsetPage}
        isLoadingOptions={isLoadingOptions}
        isClearable
        inModal={inModal ?? false}
        placeholder={filter?.label}
        filterOption={filterOption}
        noOptionsText={
          (searchInput?.[searchKey] || "")?.length < 3
            ? noOptionsMessage.label
            : noOptionsMessage.title
        }
        ref={ref}
      />
    );
  },
);
