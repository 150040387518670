// Arbitrarily nested data
import classNames from "classnames";
import { Shape } from "components/shape";
import { useContentsetContext } from "contexts/contentset-context";
import { useDidMountEffect, useLabels } from "helpers/hooks";
import { shameScroll } from "helpers/shame-scroll";
import { cloneDeep } from "lodash-es";
import { useState } from "react";
import { Form } from "react-bootstrap";
import { Transition } from "react-transition-group";
import { colors } from "theme/theme";
import { ProcessedFilter } from "../../../constants/types";
import { Button } from "../../button";
import { FilterList } from "../filter-list";

export const FilterComponent = ({
  filters,
  base,
  detailEntityType,
}: {
  base: any;
  detailEntityType: string;
  filters: ProcessedFilter[];
}) => {
  const {
    modalState,
    setModalState,
    selectedOptions,
    setSelectedOptions,
    modalTarget,
    clearFilters,
  } = useContentsetContext();
  const [filterBtn, resetBtn] = useLabels(
    ["ui-618", "Filter"],
    ["ui-1", "Reset all filters"],
  );

  const [update, forceUpdate] = useState<boolean>(true);
  const [closing, setClosing] = useState(false);

  useDidMountEffect(() => {
    setSelectedOptions(cloneDeep(selectedOptions));
  }, [update]);

  return (
    <Transition
      in={modalState}
      mountOnEnter
      unmountOnExit
      appear
      timeout={300}
      onEnter={() =>
        modalState &&
        document
          .querySelector(`[data-scroll-target=${modalTarget}]`)
          ?.scrollIntoView()
      }
    >
      {(state) => (
        <div className="filter-modal">
          <div
            className={classNames(
              "filter-modal-content",
              state && `filter-modal-content-${state}`,
            )}
          >
            <header className="filter-modal__header">
              <Form.Label>
                <h4>
                  {filterBtn.label}{" "}
                  {
                    base?.search?.entity_class.find(
                      (element) => element.code === detailEntityType,
                    )?.label
                  }
                </h4>
              </Form.Label>

              <div
                className="filter-modal__close"
                onClick={() => {
                  setModalState(false);
                  shameScroll({ close: true });
                }}
              >
                <Shape variant="close-big" fill={colors.white} />
              </div>
            </header>

            <Form className="filter-modal__form">
              <FilterList
                filters={filters}
                selectedOptions={selectedOptions}
                setSelectedOptions={setSelectedOptions}
                forceUpdate={() => forceUpdate(!update)}
                level={0}
                path=""
              />
            </Form>

            <div className="filter-controls">
              <Button
                label={filterBtn.label}
                variant="primary"
                onClick={() => {
                  setModalState(false);
                  shameScroll({ close: true });
                }}
              />
              <Button
                label={resetBtn.label}
                variant="tertiary-outline"
                icon="close"
                onClick={clearFilters}
              />
            </div>
          </div>
          <div
            className={classNames(
              "close-filter-modal",
              state && `close-filter-modal-${state}`,
            )}
            onClick={() => {
              if (!modalState && closing) return;
              setClosing(true);
              setModalState(false);
              shameScroll({ close: true });
            }}
          ></div>
        </div>
      )}
    </Transition>
  );
};
