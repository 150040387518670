import { MutableRefObject } from "react";

export type PositionType = "start" | "end" | "center" | null;
export type AngleType = "up-right" | "up-left" | null;

// helper function to create a polygon string with exact angle-degrees
export const createClipPathPolygon = (
  element: HTMLElement,
  angleDegrees: number,
  position: PositionType,
  angle: AngleType = "up-right",
): string => {
  const angleRadians = angleDegrees * (Math.PI / 180);
  const tanAngle = Math.tan(angleRadians);

  if (element) {
    const height = element.offsetHeight;
    const width = element.offsetWidth;

    const xOffsetPixels = tanAngle * height;
    const xOffsetPercent = Math.round((xOffsetPixels / width) * 100);

    if (position === "end") {
      return angle === "up-right"
        ? `polygon(${xOffsetPercent}% 0, 100% 0, 100% 100%, 0% 100%)`
        : `polygon(0 0, 100% 0, 100% 100%, ${xOffsetPercent}% 100%)`;
    } else if (position === "start") {
      return angle === "up-right"
        ? `polygon(0 0, 100% 0, ${100 - xOffsetPercent}% 100%, 0 100%)`
        : `polygon(0 0, ${100 - xOffsetPercent}% 0, 100% 100%, 0 100%)`;
    } else {
      // position === "center"
      return angle === "up-right"
        ? `polygon(${xOffsetPercent}% 0, 100% 0, ${
            100 - xOffsetPercent
          }% 100%, 0 100%)`
        : `polygon(0 0, ${
            100 - xOffsetPercent
          }% 0, 100% 100%, ${xOffsetPercent}% 100%)`;
    }
  }

};

// function to use in card-navigation component to update clipPath based on grid layout (tiles_per_row)
export const updateClipPaths = (
  elements:
    | HTMLElement
    | HTMLElement[]
    | MutableRefObject<HTMLElement>
    | null
    | undefined,
  angleDegrees: number,
  tilesPerRow: number,
  diagonalBorder: boolean,
  headerIsPartOfGrid: boolean = false,
  angle: AngleType = "up-right",
): string[] => {
  const elementArray =
    elements instanceof HTMLElement
      ? [elements]
      : Array.isArray(elements)
      ? elements
      : elements?.current
      ? [elements.current]
      : [];

  if (tilesPerRow <= 1 || !diagonalBorder) {
    elementArray.forEach((element) => {
      if (element) {
        element.style.clipPath = "";
      }
    });
    return;
  }

  let positions: PositionType[];

  if (headerIsPartOfGrid) {
    positions = ["center", "end"];
  } else {
    positions = elementArray.map((_, index) => {
      const rowIndex = (index + 1) % tilesPerRow;
      return tilesPerRow > 1
        ? rowIndex === 1
          ? "start"
          : rowIndex === 0
          ? "end"
          : "center"
        : null;
    });
  }

  return elementArray.map((element, index) => {
    const position = positions[index];
    return createClipPathPolygon(element, angleDegrees, position, angle);
  });
};
