import { Cloudinary } from "components/cloudinary";
import { colors } from "theme/theme";
import { LoadingSpinner } from "components/loading-spinner";
import { sanitize, stripHtmlTags } from "helpers/text-processing";
import { SelectGroup, SelectOption, SelectProps } from "constants/types";
import { Shape } from "components/shape";
import { useLabels } from "helpers/hooks";
import AsyncSelect from "react-select/async";
import classNames from "classnames";
import Control from "./components/Control";
import Image from "next/image";
import MenuList from "../menu-list";
import ReactSelect, { components, createFilter } from "react-select";

import React, {
  FocusEvent,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";

const calcOptionsLength = (options: SelectOption[] | SelectGroup[]) => {
  if (options?.[0]?.options) {
    return options.reduce((result: number, group: SelectGroup) => {
      return result + group.options.length;
    }, 0);
  }
  return options.length;
};

export type SelectHandle = {
  handleExternalClick: (event) => void;
};

export const Select = forwardRef<SelectHandle, SelectProps>(function Select(
  {
    className,
    externalInput,
    options,
    value,
    width,
    asyncOptions,
    filterOption,
    handleChange,
    handleInputChange,
    searchFunction,
    border = true,
    focusOnMount = false,
    id = `react-select__${Math.random().toString(36).substring(7)}`,
    inModal = false,
    isClearable = true,
    isDisabled = false,
    isDropdownSearch = false,
    isLoading = false,
    isLoadingOptions = false,
    isSearch = false,
    menuPlacement = "auto",
    noOptionsText,
    placeholder = "",
  },
  ref,
) {
  const inputElement = useRef<HTMLInputElement>(null);

  const [inputValue, setInputValue] = useState("");
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [noOptionsMessage] = useLabels(["ui-585", "No options"]);

  if (!noOptionsText) {
    noOptionsText = noOptionsMessage.title;
  }
  // Styles
  const styles = {
    container: (provided: object) => ({
      ...provided,
      ...(width && { width }),
    }),
  };

  useImperativeHandle(ref, () => ({
    handleExternalClick: (event) => {
      if (document.getElementById(id).contains(event.target)) {
        // Prevent interaction when select itself is clicked
        return;
      }

      const element = inputElement.current;

      if (element) {
        const { menuIsOpen } = element.state;

        if (!menuIsOpen) {
          element.focus();
        } else {
          element.blur();
        }
      }
    },
  }));

  useEffect(() => {
    if (focusOnMount) inputElement?.current?.focus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isWindowed = useMemo(
    () => options && calcOptionsLength(options) >= 50,
    [options],
  );

  // Components
  const ClearIndicator = (props) => {
    return (
      <components.ClearIndicator {...props}>
        <Shape variant="close" size={18} fill={colors.gray60} />
      </components.ClearIndicator>
    );
  };
  const DropdownIndicator = (props) => {
    return !isSearch || isDropdownSearch ? (
      <components.DropdownIndicator {...props}>
        {isLoading ? (
          <LoadingSpinner size={18} />
        ) : (
          <Image
            src="/assets/shape/caret-down-small-gray60.svg"
            alt="dropdown"
            width={18}
            height={18}
          />
        )}
      </components.DropdownIndicator>
    ) : (
      <></>
    );
  };
  const Menu = (props) => {
    return (
      <components.Menu
        {...props}
        className={classNames(className && `${className}-menu`)}
      >
        {props.children}
      </components.Menu>
    );
  };
  const Option = (props) => {
    const { onMouseMove, onMouseOver, ...rest } = props.innerProps;
    const newProps = { ...props, innerProps: rest };
    return (
      <components.Option {...newProps} className="custom-option">
        <span>
          {props.data.image && <Cloudinary {...props.data.image} ar="ar11" />}
        </span>
        {props.data?.reactElement ?? null}
        <span
          dangerouslySetInnerHTML={{
            __html: sanitize(props.data.label),
          }}
        ></span>
      </components.Option>
    );
  };
  const SingleValue = (props) => {
    return (
      <components.SingleValue {...props}>
        {props.data.image &&
          props.data.image.media.digital_asset_id !==
            "im-background/grey-triangle-1-1" && (
            <Cloudinary {...props.data.image} ar="ar11" />
          )}
        {props.data?.reactElement ?? null}
        <span
          dangerouslySetInnerHTML={{
            __html: sanitize(props.data.label),
          }}
        ></span>
      </components.SingleValue>
    );
  };

  const updateInput = (newInput: string) => {
    setInputValue(newInput);
    handleInputChange && handleInputChange(newInput);
  };

  const getUniqueOption = () => {
    const filteredOptions = options.filter((option) => {
      if (filterOption && "value" in option) {
        return filterOption(
          {
            label: option.label,
            value: option.value,
            data: { ...option },
          },
          inputValue,
        );
      }

      return option.label.toLowerCase().includes(inputValue.toLowerCase());
    });

    return filteredOptions.length === 1
      ? (filteredOptions[0] as SelectOption)
      : null;
  };

  const onBlur = () => {
    if (isSearch) {
      if (value) {
        handleChange({
          ...value,
          label: stripHtmlTags(value.label),
        });
        updateInput("");
      } else if (searchFunction) {
        searchFunction();
      }
    }
  };

  const onFocus = (e: FocusEvent<HTMLInputElement>) => {
    const isDropdownInput: boolean = !isSearch || isDropdownSearch;

    if (isSearch && value && value?.label) {
      const { label } = value;

      updateInput(label);

      const targetInput = e.target;

      setTimeout(() => {
        // Firefox and Opera
        targetInput.focus();

        const length = targetInput.value?.length;

        targetInput.setSelectionRange(length, length);
      }, 1);
    }
    setMenuIsOpen(isDropdownInput);
  };

  const onKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter" && isSearch && searchFunction) {
      event.stopPropagation();
      event.preventDefault();

      const option = getUniqueOption();

      if (option) {
        handleChange(option);
      } else {
        searchFunction();
      }
    }
  };

  const onInputChange = (newInputValue: string, { action }) => {
    if (action === "input-change") {
      if (value) {
        handleChange(null);
      }

      updateInput(newInputValue);
      setMenuIsOpen(newInputValue.length > 0);
    } else if (action === "menu-close") {
      inputElement.current.blur();
      setMenuIsOpen(false);
    }
  };

  const onChange = (newValue: SelectOption | null) => {
    value = newValue;
    if (newValue) {
      handleChange({
        ...newValue,
        label: stripHtmlTags(newValue.label),
      });
    } else handleChange(null);
    updateInput("");
  };

  const theme = (theme: { colors: object }) => {
    const customTheme = {
      ...theme,
      borderRadius: 0,
      borderWidth: 1,
      fontSize: 14,
      lineHeight: 21,
      colors: { ...theme.colors, primary: colors.whiteHighlight },
    };

    return customTheme;
  };

  const selectConfig = {
    ref: inputElement,
    className: classNames(
      "react-select__container",
      value && "has_value",
      border ? "has_border" : "no_border",
      !isSearch && !isDropdownSearch && "no_typing",
      className,
    ),
    classNamePrefix: "react-select",
    components: {
      ClearIndicator,
      Control,
      DropdownIndicator,
      Menu,
      Option,
      SingleValue,
      // Only use windowed menu list in non async select and above threshold of options
      ...(isWindowed && { MenuList }),
    },
    customFilter: createFilter({ ignoreAccents: false }),
    id,
    inputValue: externalInput ?? inputValue,
    isClearable,
    isDisabled,
    isSearchable: isSearch,
    loading: isLoadingOptions,
    menuIsOpen,
    menuPlacement,
    menuPosition: inModal ? "fixed" : "absolute",
    menuShouldBlockScroll: inModal,
    openMenuOnFocus: true,
    placeholder,
    styles,
    value,
    loadingMessage: () => {
      return <LoadingSpinner size={18} />;
    },
    noOptionsMessage: () => {
      return isLoadingOptions ? <LoadingSpinner size={18} /> : noOptionsText;
    },
    onBlur: onBlur,
    onChange: onChange,
    onFocus: onFocus,
    onInputChange: onInputChange,
    onKeyDown: onKeyDown,
    theme: theme,
    ...(filterOption && { filterOption }),
    // use focusOnMount to determine if this is the lightbox search ...
    // otherwise we need to hack around z-index stuff etc.
    ...(typeof document !== "undefined" && !focusOnMount
      ? {
          menuPortalTarget: document.body,
        }
      : {}),
  };

  return options ? (
    <ReactSelect {...selectConfig} options={options} />
  ) : asyncOptions ? (
    <AsyncSelect {...selectConfig} loadOptions={asyncOptions} />
  ) : (
    // eslint-disable-next-line no-console
    <>{console.error("Please provide options to select.")}</>
  );
});
