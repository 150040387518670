import classNames from "classnames";
import { Icon, Shape } from "components/shape";
import { usePrevious } from "helpers/hooks";
import { getSpeakingId } from "helpers/text-processing";
import { useRouter } from "next/router";
import {
  CSSProperties,
  Children,
  FC,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { TabsProps } from "../../constants/types";
import { colors } from "../../theme/theme";

export const Tabs: FC<TabsProps> = ({
  activeTabIndex,
  defaultTabIndex,
  alignment = "spread",
  children,
  tabs = [],
  stickyNavigation,
  onSelect,
  infoBox,
  className,
  tabNavClassName,
}) => {
  // sticky navigation
  const [isSticking, setSticking] = useState(false);
  const [isScrolledOver, setScrolledOver] = useState(false);
  const [tabHeight, setTabHeight] = useState(50);

  const wrapperRef = useRef<HTMLDivElement>();

  useEffect(() => {
    const tab = wrapperRef.current?.querySelector<HTMLElement>(".nav-link");

    const tabHeightObserver = new ResizeObserver((entries) => {
      const height = entries[0].borderBoxSize[0].blockSize;
      height >= 50 && setTabHeight(height);
    });
    if (tab) {
      tabHeightObserver.observe(tab);
    }

    if (typeof document === "undefined" || !stickyNavigation)
      return () => tabHeightObserver.disconnect();

    const wrapper = wrapperRef.current;
    const nav = wrapperRef.current?.querySelector<HTMLElement>(
      ".tab-primitive__nav--sticky",
    );

    const scrollHandler = () => {
      const navHeight = nav.offsetHeight;

      const paddingTop = parseInt(
        window.getComputedStyle(wrapper, null).getPropertyValue("padding-top"),
        10,
      );
      const clientTop = wrapper?.getBoundingClientRect().top + paddingTop;
      const height = wrapper?.offsetHeight - paddingTop;

      const stickyHeight = parseInt(
        getComputedStyle(wrapperRef.current).getPropertyValue(
          "--navigation-height",
        ),
        10,
      );

      const newSticking =
        clientTop < stickyHeight &&
        clientTop > -height + stickyHeight + navHeight;

      setSticking(newSticking);
      setScrolledOver(clientTop <= -height + stickyHeight + navHeight);
    };

    window.addEventListener("scroll", scrollHandler);

    return () => {
      window.removeEventListener("scroll", scrollHandler);
      tabHeightObserver.disconnect();
    };
  }, [wrapperRef, stickyNavigation]);

  const scrollToTop = useCallback(() => {
    const scrollTop = document.documentElement.scrollTop;
    const wrapperOffsetTop = wrapperRef.current?.getBoundingClientRect().top;
    const stickyHeight = parseInt(
      getComputedStyle(wrapperRef.current).getPropertyValue(
        "--navigation-height",
      ),
      10,
    );

    if (wrapperOffsetTop < stickyHeight) {
      window.scrollTo({
        top: wrapperOffsetTop + scrollTop - stickyHeight,
        behavior: "smooth",
      });
      setTimeout(() => {
        const stickyHeight = parseInt(
          getComputedStyle(wrapperRef.current).getPropertyValue(
            "--navigation-height",
          ),
          10,
        );
        window.scrollTo({
          top: wrapperOffsetTop + scrollTop - stickyHeight,
          behavior: "smooth",
        });
      }, 50);
    }
  }, []);

  // active tab
  const { asPath } = useRouter();
  const hash = asPath.split("#")?.[1]?.toLowerCase();
  const [internalActiveIndex, setActiveIndex] = useState<number>(
    defaultTabIndex ?? 0,
  );
  const activeIndex = activeTabIndex ?? internalActiveIndex;
  const activeNavigationItem =
    wrapperRef.current?.querySelectorAll<HTMLAnchorElement>(
      ".tab-primitive__nav__item",
    )?.[activeIndex];

  useEffect(() => {
    const newActiveIndex = tabs?.length
      ? tabs.findIndex(
          (tab) => tab?.title && getSpeakingId({ title: tab.title }) === hash,
        )
      : Children.toArray(children).findIndex(
          (tab) =>
            typeof tab === "object" &&
            "props" in tab &&
            tab.props?.tabTitle &&
            typeof tab.props.tabTitle === "string" &&
            getSpeakingId({ title: tab.props.tabTitle }) === hash,
        );
    if (newActiveIndex === -1) return;

    setActiveIndex(newActiveIndex !== -1 ? newActiveIndex : 0);
    onSelect?.(newActiveIndex);
    wrapperRef.current
      ?.querySelectorAll<HTMLElement>(".tab-primitive__nav__item")
      ?.[newActiveIndex]?.scrollIntoView({
        behavior: "smooth",
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps -- tabs could change on every render
  }, [asPath, children, hash]);

  useEffect(() => {
    // Always scroll active item into view
    const navigationWrapper = wrapperRef.current.querySelector<HTMLElement>(
      ".tab-primitive__nav",
    );
    if (!navigationWrapper || !activeNavigationItem) return;
    const navigationWrapperRect = navigationWrapper.getBoundingClientRect();
    const navigationWrapperScrollLeft = navigationWrapper.scrollLeft;

    const activeItemRect = activeNavigationItem.getBoundingClientRect();

    ["left", "right"].forEach((side) => {
      const valueWhichShouldBeBigger =
        side === "left" ? activeItemRect[side] : navigationWrapperRect[side];
      const valueWhichShouldBeSmaller =
        side === "left" ? navigationWrapperRect[side] : activeItemRect[side];
      if (valueWhichShouldBeBigger < valueWhichShouldBeSmaller) {
        navigationWrapper.scrollTo({
          left:
            navigationWrapperScrollLeft +
            (activeItemRect[side] - navigationWrapperRect[side]),
          behavior: "smooth",
        });
      }
    });
  }, [activeIndex]);

  // Animate height
  const contentWrapperRef = useRef<HTMLDivElement>();
  const previous = usePrevious({ activeNavigationItem });

  const animateHeight = useCallback(
    (oldHeight: number, newHeight: number) => {
      if (!contentWrapperRef.current) {
        return;
      }
      const newNavigationWrapper = activeNavigationItem;

      contentWrapperRef.current.style.height = `${oldHeight}px`;
      contentWrapperRef.current.style.overflow = "hidden";
      newNavigationWrapper.style.overflow = "visible";

      // double requestAnimationFrame for firefox
      window.requestAnimationFrame(() =>
        window.requestAnimationFrame(() => {
          contentWrapperRef.current.style.height = `${newHeight}px`;
          setTimeout(() => {
            contentWrapperRef.current.style.height = "";
            contentWrapperRef.current.style.overflow = "";
            newNavigationWrapper.style.overflow = "";
          }, 250);
        }),
      );
    },
    [activeNavigationItem],
  );

  useEffect(() => {
    const previousTabId = previous?.activeNavigationItem?.getAttribute("href");
    const previousPanel = wrapperRef.current?.querySelector(previousTabId);
    const previousPanelHeight = previousPanel?.clientHeight;

    const newTabId = activeNavigationItem?.getAttribute("href");
    const newPanel = wrapperRef.current?.querySelector(newTabId);
    const newPanelHeight = newPanel?.clientHeight;

    if (!previousPanel || !newPanel) {
      return;
    }

    animateHeight(previousPanelHeight, newPanelHeight);

    // don't trigger on previous value change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeNavigationItem]);

  // Render tabs
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    if (loaded) return;
    setLoaded(true);
  }, []);

  const tabItems = useMemo(
    () => [
      ...tabs
        .map((tab) => {
          const icon = tab.icon ? (
            <Shape
              variant={tab.icon as Icon}
              size={36}
              fill={colors.blue}
              className="mr-2"
            />
          ) : null;

          if (!tab?.component) return null;

          return {
            title: tab.title,
            navigation: (
              <>
                {icon} {tab.title ?? "Product highlights"}
              </>
            ),
            content: !infoBox ? (
              tab.component
            ) : (
              <>
                {!tab?.disableHeader && (
                  <div className="info-header">
                    <h3 className="h4 title border-bottom">{tab.title}</h3>
                  </div>
                )}
                <div className="info-body" id={tab?.id}>
                  {tab.component}
                </div>
              </>
            ),
          };
        })
        .filter(Boolean),
      ...(Children.count(children) > 0
        ? Children.map(children, (child: ReactElement) => {
            return {
              title: String(child?.props?.tabTitle),
              navigation: child?.props?.tabTitle,
              content: !infoBox ? (
                child
              ) : (
                <>
                  <div className="info-header">
                    <h3 className="h4 title border-bottom">
                      {child?.props?.tabTitle}
                    </h3>
                  </div>
                  <div className="info-body">{child}</div>
                </>
              ),
            };
          })
        : []),
    ],
    [tabs, children, infoBox],
  );

  return (
    <div
      className={classNames("tab-primitive", className)}
      ref={wrapperRef}
      style={
        {
          "--tab-height": stickyNavigation ? `${tabHeight}px` : null,
        } as CSSProperties
      }
    >
      <nav
        role="tablist"
        className={classNames(
          "tab-primitive__nav",
          // Variants
          stickyNavigation && "tab-primitive__nav--sticky",
          isSticking && "tab-primitive__nav--sticking",
          isScrolledOver && "tab-primitive__nav-scrolled-over",
          tabHeight > 64 && "tab-primitive__nav--big",
          alignment === "left" && "tab-primitive__nav--left",
          tabs.some((x) => x.icon) && "tab-primitive__nav--icon",
          // Custom class
          tabNavClassName,
        )}
      >
        {tabItems.map(({ title, navigation }, index) => {
          const active = activeIndex === index;
          return (
            <a
              href={`#${getSpeakingId({ title })}`}
              role="tab"
              aria-selected={active}
              className={classNames(
                "tab-primitive__nav__item nav-item nav-link",
                active && "tab-primitive__nav__item--active",
              )}
              onClick={(event) => {
                event.preventDefault();
                scrollToTop();
                setActiveIndex(index);
                onSelect?.(index);
              }}
              onKeyDown={(event) => {
                if (event.key === "Enter" || event.key === " ") {
                  event.preventDefault();
                  event.currentTarget.click();
                }
                if (event.key === "ArrowLeft" || event.key === "ArrowRight") {
                  event.preventDefault();
                  if (event.key === "ArrowLeft" && activeIndex <= 0) return;
                  if (
                    event.key === "ArrowRight" &&
                    activeIndex >= tabItems.length - 1
                  )
                    return;
                  const newIndex =
                    activeIndex + (event.key === "ArrowLeft" ? -1 : 1);
                  setActiveIndex(newIndex);
                  onSelect?.(newIndex);
                  const newNavItem =
                    wrapperRef.current?.querySelectorAll<HTMLElement>(
                      ".tab-primitive__nav__item",
                    )?.[newIndex];
                  newNavItem?.focus();
                }
              }}
              key={index}
            >
              {navigation}
            </a>
          );
        })}
        <TabIndicator activeNavigationItem={activeNavigationItem} />
      </nav>
      <div
        className={classNames(
          "tab-primitive__content",
          infoBox && "tab-primitive__content--info-box",
        )}
        ref={contentWrapperRef}
      >
        {tabItems
          .map((tab, index) => ({ ...tab, index }))
          .filter((_, index) => index === activeIndex || loaded)
          .map(({ title, content, index }) => {
            const active = activeIndex === index;
            return (
              <div
                role="tabpanel"
                id={getSpeakingId({ title })}
                className={classNames(
                  "tab-primitive__content__item",
                  active && "tab-primitive__content__item--active",
                )}
                aria-hidden={!active}
                inert={!active ? "" : undefined}
                key={index}
              >
                {content}
              </div>
            );
          })}
      </div>
    </div>
  );
};

type TabIndicatorProps = {
  activeNavigationItem: HTMLElement;
};

function TabIndicator({ activeNavigationItem }: TabIndicatorProps) {
  const [indicatorWidth, setIndicatorWidth] = useState(
    activeNavigationItem?.clientWidth,
  );
  const [indicatorLeft, setIndicatorLeft] = useState(
    activeNavigationItem?.offsetLeft,
  );

  useEffect(() => {
    if (!activeNavigationItem) return;

    setIndicatorWidth(activeNavigationItem.clientWidth);
    setIndicatorLeft(activeNavigationItem.offsetLeft);

    // update width on change
    const resizeObserver = new ResizeObserver((entries) => {
      setIndicatorWidth(entries[0].borderBoxSize[0].inlineSize);
    });
    resizeObserver.observe(activeNavigationItem);

    // update left on change
    const mutationObserver = new MutationObserver(() => {
      setIndicatorLeft(activeNavigationItem.offsetLeft);
    });
    mutationObserver.observe(activeNavigationItem.parentElement, {
      childList: true,
    });

    return () => {
      resizeObserver.disconnect();
      mutationObserver.disconnect();
    };
  }, [activeNavigationItem]);

  return (
    <span
      className="tab-primitive__nav__indicator"
      style={
        activeNavigationItem && {
          width: indicatorWidth,
          left: indicatorLeft,
        }
      }
    />
  );
}
