import { Card } from "components/card";
import { SearchFilter } from "components/contentset/search-filter";
import { Select, SelectHandle } from "components/forms/select";
import { FilterPreviewWithFilter, Media, SelectOption } from "constants/types";
import { useContentsetContext } from "contexts/contentset-context";
import { createUniqueID } from "helpers/contentset/functions";
import { useLabels } from "helpers/hooks";
import { omit } from "lodash-es";
import { FC, useEffect, useMemo, useRef, useState } from "react";
import { Row } from "react-bootstrap";

const Preview: FC<{ preview: FilterPreviewWithFilter }> = ({ preview }) => {
  const {
    selectedOptions,
    setSelectedOptions,
    searchValue,
    setSearchInput,
    contentsetWrapperRef,
    isLoadingContentsetPage,
  } = useContentsetContext();

  const [
    allPrefix,
    allBrochuresLabel,
    allCertificatesLabel,
    allDataSheetsLabel,
    allManualsLabel,
    allSoftwareLabel,
    allVideosLabel,
  ] = useLabels(
    ["ui-873", "All"],
    ["ui-881", "All Brochures"],
    ["ui-882", "All Certificates"],
    ["ui-883", "All Data sheets"],
    ["ui-884", "All Manuals"],
    ["ui-885", "All Software"],
    ["ui-886", "All Videos"],
  );
  const isSearch = preview?.filter?.filter_type === "single-value-dropdown";

  const labels = {
    "filter-105": allBrochuresLabel.label,
    "filter-106": allCertificatesLabel.label,
    "filter-104": allDataSheetsLabel.label,
    "filter-103": allManualsLabel.label,
    "filter-107": allSoftwareLabel.label,
    "filter-108": allVideosLabel.label,
  };

  // Create option array for select
  const options = preview?.filter
    ? [
        ...(preview.selectedOptionId
          ? [
              {
                label:
                  labels[preview.filter.id] ||
                  `${allPrefix.label} ${preview.title}`,
                value: preview.selectedOptionId,
                preview: preview.preview,
              },
            ]
          : []),
        ...preview.filter.options
          .map((option) => ({
            label: option.label,
            value: createUniqueID(option, preview.filter),
            preview: option.preview,
          }))
          .filter(
            (option) =>
              isLoadingContentsetPage || option.preview?.currentMaximum,
          ),
      ]
    : [];

  const [value, setValue] = useState<SelectOption>();
  const [input, setInput] = useState<string>("");

  // Clear value if there are changes of filter/search in other locations
  useEffect(() => {
    isSearch && setValue(null);
  }, [isSearch, searchValue]);

  useEffect(() => {
    !isSearch && setValue(null);
  }, [isSearch, selectedOptions]);

  const handleChange = (newValue: SelectOption | string) => {
    if (isSearch) {
      if (typeof newValue === "string") {
        setInput(newValue);
      }

      if (typeof newValue === "object") {
        setSearchInput((searchInput) =>
          omit(searchInput, [preview?.filter?.filter_attribute]),
        );
        setInput("");
      }
      return;
    }

    if (isSearch || typeof newValue !== "object") return;

    const newSelectedOptions = (() => {
      const selectedOptionValue = newValue ? { [newValue.value]: {} } : {};

      if (
        preview.selectedOptionId &&
        newValue.value !== preview.selectedOptionId
      ) {
        return {
          [preview.selectedOptionId]: selectedOptionValue,
        };
      }

      return selectedOptionValue;
    })();

    setSelectedOptions(newSelectedOptions);
    setValue(newValue);
  };

  const inputRef = useRef<SelectHandle | null>(null);
  const filterPreviewRef = useRef(null);

  useEffect(() => {
    // Prevent mousedown and mouseup events getting triggered by clicking the div
    // and handle it manually in the handleExternalClick function
    const handleDocumentClick = (event) => {
      if (
        filterPreviewRef.current?.contains(event.target) // Ensure the click is inside the desired div
      ) {
        event.preventDefault();
        inputRef.current?.handleExternalClick(event);
      }
    };

    // Attach the event listener
    document.addEventListener("mousedown", handleDocumentClick);
    document.addEventListener("mouseup", handleDocumentClick);

    // Cleanup on unmount
    return () => {
      document.removeEventListener("mousedown", handleDocumentClick);
      document.removeEventListener("mouseup", handleDocumentClick);
    };
  }, []);

  return (
    <div
      className="filter-preview col-lg-3 col-md-6 col-xs-12"
      ref={filterPreviewRef}
      onClick={(event) => {
        inputRef.current?.handleExternalClick(event);
      }}
    >
      <Card
        title={preview.title}
        html={preview.excerpt}
        cardImg={{
          media: {
            digital_asset_transformation:
              "h_860/w_860/e_colorize,co_rgb:005DA8/u_im-background:grey-triangle-16-9.jpg,w_3600",
            digital_asset_id: `icons/${preview.icon}`,
          } as Media,
        }}
      >
        <div className="filter-preview-input-wrapper">
          {isSearch ? (
            <SearchFilter
              filter={preview?.filter}
              externalInput={{
                value: input,
                handleChange: (newValue) => handleChange(newValue),
              }}
              externalState={{
                value,
                handleChange: (newValue) => handleChange(newValue),
              }}
              searchFunction={() => {
                if (input === "") return;

                setSearchInput({
                  [preview?.filter?.filter_attribute]: input,
                });

                if (contentsetWrapperRef.current) {
                  setTimeout(() => {
                    contentsetWrapperRef.current.scrollIntoView({
                      behavior: "smooth",
                    });
                  }, 200);
                }
              }}
              ref={inputRef}
            />
          ) : (
            options.length > 0 && (
              <Select
                options={options}
                value={value}
                handleChange={(newValue) => {
                  handleChange(newValue);

                  if (contentsetWrapperRef.current) {
                    setTimeout(() => {
                      contentsetWrapperRef.current.scrollIntoView({
                        behavior: "smooth",
                      });
                    }, 200);
                  }
                }}
                placeholder={preview.title}
                ref={inputRef}
              />
            )
          )}
        </div>
      </Card>
    </div>
  );
};

export const FilterPreview = () => {
  const { filters } = useContentsetContext();

  // Get previews out of filters array
  const previews: FilterPreviewWithFilter[] = useMemo(() => {
    return filters.reduce((acc, filter) => {
      const optionPreviews = filter.options
        .filter((option) => option.filter_preview?.length && option.has_preview)
        .map((option) => ({
          ...option.filter_preview[0],
          selectedOptionId: createUniqueID(option, filter),
          preview: option.preview,
          filter: filter.child_filter.filter(
            ({ id }) => id === option.filter[0],
          )[0],
        }));
      const preview = { ...filter.filter_preview?.[0], filter };
      return [
        ...acc,
        ...(filter.has_preview && filter.filter_preview?.[0] ? [preview] : []),
        ...(optionPreviews ?? []),
      ];
    }, []);
  }, [filters]);

  return (
    <Row className="filter-preview-wrapper">
      {previews
        .filter(({ preview }) => {
          const currentMaximum = preview?.currentMaximum;
          return typeof currentMaximum !== "number" || currentMaximum > 0;
        })
        .map((preview) => (
          <Preview key={preview.title} preview={preview} />
        ))}
    </Row>
  );
};
