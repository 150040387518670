import classNames from "classnames";
import { SearchFilter } from "components/contentset/search-filter";
import { Checkbox } from "components/forms/checkbox";
import { LoadingSpinner } from "components/loading-spinner";
import { Shape } from "components/shape";
import { FilterListProps, Option, ProcessedFilter } from "constants/types";
import { useContentsetContext } from "contexts/contentset-context";
import { createUniqueID, filterTypeMap } from "helpers/contentset/functions";
import { useCallback, useState } from "react";
import { Row as BootstrapRow, Col, Form } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import { colors } from "theme/theme";

export const FilterList = ({
  filters,
  selectedOptions,
  setSelectedOptions,
  forceUpdate,
  level,
  path,
}: FilterListProps) => {
  const { contentset, isLoadingContentsetPage, modalTarget } =
    useContentsetContext();

  // Update selectedOptions when checkbox is clicked
  const handleCheckboxClicked = useCallback(
    (
      optionCode: string,
      type: "radio" | "checkbox",
      filterOptions: Option[],
    ) => {
      if (selectedOptions[optionCode]) {
        delete selectedOptions[optionCode];
      } else {
        if (type === "radio") {
          filterOptions.map(
            (item) =>
              delete selectedOptions[
                `${item.parent}§${item.code}§${item.sourceType}§radio`
              ],
          );
        }
        selectedOptions[optionCode] = {};
      }
      setSelectedOptions(selectedOptions);
      forceUpdate();
    },
    [selectedOptions, setSelectedOptions, forceUpdate],
  );

  // Function used to update selectedOptions on higher level because this component is rendered recursively
  const handleSubFiltersListChange = useCallback(
    (optionCode: string, subSelections: any) => {
      selectedOptions[optionCode] = subSelections;
      setSelectedOptions(selectedOptions);
    },
    [selectedOptions, setSelectedOptions],
  );

  const [currentActiveKeys, setCurrentActiveKeys] = useState<string[]>(
    level === 0 &&
      filters
        .filter((filter) => {
          const childFilterIsModalTarget = (filter: ProcessedFilter) => {
            return filter.child_filter?.some(
              (childFilter) =>
                childFilter.id === modalTarget ||
                childFilterIsModalTarget(childFilter),
            );
          };

          return filter.id === modalTarget || childFilterIsModalTarget(filter);
        })
        .map((filter) => `0${filter.id}`),
  );

  const toggleActiveKey = (key: string) => {
    setCurrentActiveKeys((currentActiveKeys) =>
      currentActiveKeys.includes(key)
        ? currentActiveKeys.filter((activeKey) => activeKey !== key)
        : [...currentActiveKeys, key],
    );
  };

  return (
    <Form.Group>
      {filters.map((filter) => {
        // Add unique ID to each option
        const options = filter.options
          .map((option) => {
            const uniqueID = createUniqueID(option, filter);
            return { uniqueID, ...option };
          })
          .filter((option) => {
            const previewString = option.preview?.state?.[path]?.visibility;
            return Boolean(previewString) && previewString !== "hide";
          });

        if (!options.length && filter.filter_type !== "single-value-dropdown")
          return null;

        const key = `${level}${filter.id}`;

        return level <= 0 ? (
          <Accordion
            key={key}
            className="filter-group"
            defaultActiveKey={
              currentActiveKeys.filter(
                (currentActiveKey) => currentActiveKey === key,
              )?.[0]
            }
            data-scroll-target={filter.id}
          >
            {filterTypeMap[filter.filter_type] === "search" ? (
              <>
                <Form.Label className="visually-hidden">
                  <h3 className={level === 0 ? "h5" : "h6"}>
                    {filter.label || filter.title}
                  </h3>
                </Form.Label>
                {/* Render Searchfilter Component for Filters with "Single Value Dropdown" Type */}
                <SearchFilter filter={filter} inModal />
              </>
            ) : (
              <>
                <Accordion.Toggle
                  eventKey={key}
                  onClick={() => toggleActiveKey(key)}
                  className={classNames(
                    "filter-group__header",
                    currentActiveKeys.includes(key) &&
                      "filter-group__header--active",
                    Object.keys(selectedOptions).some(
                      (selectedOption) =>
                        selectedOption.split("§")[0] === filter.filter_category,
                    ) && "filter-group__header--selected",
                  )}
                >
                  <Form.Label>
                    <h3 className={level === 0 ? "h5" : "h6"}>
                      {filter.label || filter.title}
                    </h3>
                  </Form.Label>
                  <Shape
                    className="icon"
                    variant="caret-down-small"
                    fill={colors.gray60}
                  />
                </Accordion.Toggle>
                <Accordion.Collapse eventKey={key}>
                  <div className="filter-selectable-options">
                    {/* Render all options of this filter */}
                    {options.map((option) => {
                      const previewString =
                        option.preview?.state?.[path]?.visibility;

                      return (
                        <div
                          key={option.uniqueID}
                          className={classNames(
                            "filter-selectable-option",
                            selectedOptions[option.uniqueID] && "selected",
                            previewString === "gray" && "empty",
                            previewString === "hide" && "d-none",
                          )}
                          data-code={option.code}
                          data-parent={option.parent}
                        >
                          <Checkbox
                            id={option.uniqueID + level}
                            label={`${option.label}`}
                            supportLabel={
                              <>
                                {contentset.default_view_option_counter && (
                                  <>
                                    {isLoadingContentsetPage ? (
                                      <span>
                                        <LoadingSpinner
                                          size={14}
                                          color={colors.gray60}
                                        />
                                      </span>
                                    ) : (
                                      <span>
                                        {option.preview.currentMaximum}
                                      </span>
                                    )}
                                    <span className="filter-selectable-option-spacer">
                                      /
                                    </span>
                                    <span>{option.preview.maximum}</span>
                                  </>
                                )}
                              </>
                            }
                            type={filterTypeMap[filter.filter_type]}
                            onChange={() => {
                              handleCheckboxClicked(
                                option.uniqueID,
                                filterTypeMap[filter.filter_type],
                                filter.options,
                              );
                            }}
                            fill={colors.orange}
                            checked={Boolean(selectedOptions[option.uniqueID])}
                          />
                          {/* Check if corresponding checkbox is clicked and if filters should be rendered */}
                          {filter.has_child_filter &&
                            option.triggersChildFilter &&
                            selectedOptions[option.uniqueID] && (
                              <BootstrapRow key={option.uniqueID}>
                                <Col xs={{ span: 11, offset: 1 }}>
                                  {/* Recursively render child filters */}
                                  <FilterList
                                    filters={filter.child_filter.filter(
                                      (filterItem) => {
                                        return option.filter.includes(
                                          filterItem.id,
                                        );
                                      },
                                    )}
                                    selectedOptions={
                                      selectedOptions[option.uniqueID]
                                    }
                                    setSelectedOptions={(subSelections) =>
                                      handleSubFiltersListChange(
                                        option.uniqueID,
                                        subSelections,
                                      )
                                    }
                                    forceUpdate={forceUpdate}
                                    level={level + 1}
                                    path={`${path}${path ? "." : ""}${
                                      option.uniqueID
                                    }`}
                                  />
                                </Col>
                              </BootstrapRow>
                            )}
                        </div>
                      );
                    })}
                  </div>
                </Accordion.Collapse>
              </>
            )}
          </Accordion>
        ) : (
          <div
            key={`${level}${filter.id}`}
            className="filter-group"
            data-scroll-target={filter.id}
          >
            <Form.Label>
              <h3 className={level === 0 ? "h5" : "h6"}>
                {filter.label || filter.title}
              </h3>
            </Form.Label>
            {/* Render Searchfilter Component for Filters with "Single Value Dropdown" Type */}
            {filterTypeMap[filter.filter_type] === "search" && (
              <SearchFilter filter={filter} inModal />
            )}
            <div className="filter-selectable-options">
              {/* Render all options of this filter */}
              {options.map((option) => {
                const previewString = option.preview?.state?.[path]?.visibility;

                return (
                  <div
                    key={option.uniqueID}
                    className={classNames(
                      "filter-selectable-option",
                      selectedOptions[option.uniqueID] && "selected",
                      previewString === "gray" && "empty",
                      previewString === "hide" && "d-none",
                    )}
                    data-code={option.code}
                    data-parent={option.parent}
                  >
                    <Checkbox
                      id={option.uniqueID + level}
                      label={`${option.label}`}
                      supportLabel={
                        <>
                          {contentset.default_view_option_counter ? (
                            <>
                              {isLoadingContentsetPage ? (
                                <LoadingSpinner
                                  size={14}
                                  color={colors.gray60}
                                />
                              ) : (
                                <>{option.preview.currentMaximum}</>
                              )}
                              <> / {option.preview.maximum}</>
                            </>
                          ) : null}
                        </>
                      }
                      type={filterTypeMap[filter.filter_type]}
                      onChange={() => {
                        handleCheckboxClicked(
                          option.uniqueID,
                          filterTypeMap[filter.filter_type],
                          filter.options,
                        );
                      }}
                      fill={colors.orange}
                      checked={!!selectedOptions[option.uniqueID]}
                    />
                    {/* Check if corresponding checkbox is clicked and if filters should be rendered */}
                    {filter.has_child_filter &&
                      option.triggersChildFilter &&
                      selectedOptions[option.uniqueID] && (
                        <BootstrapRow key={option.uniqueID}>
                          <Col xs={{ span: 11, offset: 1 }}>
                            {/* Recursively render child filters */}
                            <FilterList
                              filters={filter.child_filter.filter(
                                (filterItem) => {
                                  return option.filter.includes(filterItem.id);
                                },
                              )}
                              selectedOptions={selectedOptions[option.uniqueID]}
                              setSelectedOptions={(subSelections) =>
                                handleSubFiltersListChange(
                                  option.uniqueID,
                                  subSelections,
                                )
                              }
                              forceUpdate={forceUpdate}
                              level={level + 1}
                              path={`${path}${path ? "." : ""}${
                                option.uniqueID
                              }`}
                            />
                          </Col>
                        </BootstrapRow>
                      )}
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </Form.Group>
  );
};
