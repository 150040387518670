import { useState, useEffect, FC, SVGProps } from "react";
import { ShapeProps } from "constants/types";
import classNames from "classnames";

const icons = [
  "3d-exploration",
  "add-to-compare",
  "apps",
  "big-tiles",
  "bookmark-outline",
  "bookmark",
  "caret-down-circle",
  "caret-down-circle-filled",
  "caret-down-small",
  "caret-down",
  "caret-left-circle",
  "caret-left-circle-filled",
  "caret-left-small",
  "caret-left",
  "caret-right-circle",
  "caret-right-circle-filled",
  "caret-right-small",
  "caret-right",
  "caret-up-circle",
  "caret-up-circle-filled",
  "caret-up-small",
  "caret-up",
  "certificate",
  "check",
  "circle",
  "close-big",
  "close-circle",
  "close",
  "comment",
  "configure",
  "core-fill",
  "diamond",
  "document",
  "document-types",
  "download",
  "disc",
  "display",
  "display-2",
  "envelope",
  "external-link",
  "facebook",
  "facebook-square",
  "forward",
  "filter",
  "fullscreen-button-open",
  "fullscreen-button",
  "fullscreen",
  "framed-check-filled",
  "framed-check",
  "framed-shopping-cart-eshop",
  "gear",
  "globe",
  "google-plus",
  "google-plus-square",
  "graduate-cap",
  "hard-hat-2",
  "hard-hat",
  "headset",
  "home",
  "level-selector",
  "lightbulb",
  "image",
  "insights",
  "info-circle",
  "info",
  "link",
  "linkedin",
  "linkedin-square",
  "list-thin-open",
  "list-thin",
  "list",
  "minus-circle",
  "paper-plane",
  "phone",
  "phone-envelope",
  "planningtool",
  "play",
  "play-circle-filled",
  "play-circle",
  "plus-circle",
  "plus",
  "plus-2",
  "print",
  "pick",
  "pick-2",
  "qzone",
  "relations",
  "scroll",
  "search-minus",
  "search-plus",
  "search",
  "shopping-cart-eshop",
  "shopping-cart",
  "side-menu",
  "sina-weibo",
  "small-tiles",
  "spareparts-repair",
  "step-backward",
  "step-forward",
  "stop",
  "trainings",
  "twitter",
  "twitter-square",
  "user",
  "vcard",
  "vk",
  "vk-square",
  "wechat",
  "wechat-square",
  "windowed",
  "xing",
  "xing-square",
  "youtube",
  "youtube-square",
  "zoom-video",
] as const;

export type Icon = (typeof icons)[number];

export const Shape: FC<ShapeProps> = ({
  variant,
  fill = null,
  size = 18,
  className,
}): JSX.Element | null => {
  const [ImportedIcon, setImportedIcon] = useState<FC<
    SVGProps<SVGSVGElement>
  > | null>(null);

  useEffect(() => {
    let isSubscribed = true;

    // Validate the icon variant, if it does not exist, don't import it
    if (!icons.includes(variant)) {
      return;
    }

    void (async () => {
      const importedIcon = (
        await import(`!!@svgr/webpack?-svgo,+titleProp,+ref!./${variant}.svg`)
      )?.default;
      if (isSubscribed) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        setImportedIcon(importedIcon);
      }
    })();

    return () => {
      isSubscribed = false;
    };
  }, [variant, fill, size]);

  if (!ImportedIcon) {
    return null;
  }

  return (
    <span
      className={classNames("svg", variant, className)}
      style={{
        display: "flex",
        placeItems: "center",
        alignItems: "center",
        justifyContent: "center",
        flexShrink: 0,
        flexGrow: 0,
      }}
    >
      {ImportedIcon && (
        <ImportedIcon
          fill={fill || "#E5EFF6"}
          style={{ width: size, height: size }}
        />
      )}
    </span>
  );
};
